import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import JamVideo from '../components/jam-video'
import JamBanner from '../components/jam-banner'
import styles from '../components/outdoor-jam.module.css'
import SectionHeader from '../components/section-header'

class OutdoorJamPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const images = this.props.data.data.edges
    const headerData = {
      title: 'Outdoor Jam 2021 Recap'
    }
    const headerData2 = {
      title: 'Outdoor Jam 2022 Recap'
    }

    const jamImgUrl = 'https://' + this.props.data.image.edges[0].node.file.url

    const jamImage = {
      url: jamImgUrl
    }


    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />
          <JamBanner />
          <div style={{margin: '3% 0', textAlign: 'center'}}>
          <p style={{maxWidth: '800px', margin: '0 auto', padding: '15px'}}>The Outdoor Jam is the Ben Meyer Recovery Foundation's signature fundraising event.</p>
            <p style={{maxWidth: '800px', margin: '0 auto', padding: '15px'}}>Each October, Outdoor Jammers celebrate the life of Ben and spread the message of hope through recovery by getting outside, having fun, and doing something meaningful.</p>
            <p style={{maxWidth: '800px', margin: '0 auto', padding: '15px'}}>All funds raised support our mission of preventing high-risk substance abuse, provide support for addiction recovery, and reduce the social stigma of addiction and recovery, all in Ben's honor. </p>
            </div>
          <SectionHeader data={headerData2} />
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom:'5%'}}>
              <div style={{textAlign: 'center'}}>
                <img src={jamImage.url} className={styles.jamImg}/>

                <p style={{maxWidth: '800px', margin: '5% auto', padding: '15px'}}>We could not have wished for a better outcome for our second annual Outdoor Jam. There were 16 Jams, again spanning the world, and we raised over $150,000. In addition to all the jams, we hosted a Narcan training session for festival organizers at the LEAF Festival in Asheville, teaching best practices for festival staff. Thank you to everyone who participated, whether physically or in spirit!</p>
                </div>
            </div>
          <SectionHeader data={headerData} />
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom:'5%'}}>
              <div style={{textAlign: 'center'}}>

                <p style={{maxWidth: '800px', margin: '5% auto', padding: '15px'}}>We were thrilled and humbled by the response both in terms of participation and generosity for our first annual Outdoor Jam. There were over 25 separate Jams across the world, and we raised and will match $229,715. Thank you so much to everyone who partcipated and contributed to making our first fundraiser a smashing success!</p>
                <JamVideo />
                </div>
            </div>
            <h3>Images from the trail</h3>
            <div style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
              {images.map(img => <img className={styles.jamImg} src={img.node.file.url} />)}
            </div>
        </div>
      </Layout>
    )
  }
}

export default OutdoorJamPage

export const pageQuery = graphql`
  query OutDoorJamQuery {
  data: allContentfulAsset(filter: {title: {glob: "*2021 Jam*"}}) {
  edges {
    node {
      file {
        url
      }
      contentful_id
    }
  }
},
image: allContentfulAsset(filter: {contentful_id: {eq: "UVz9Hf4Sbq5CNXJpVHpwP"}}) {
edges {
  node {
    file {
      url
    }
  }
}
}  }
`
